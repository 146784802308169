/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/mixins";

@import "./theme/variables";

@import "./theme/variables.naupaka";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ion-label {
  color: var(--ion-color-medium);
  font-size: 12px;
  &.input {
    margin-top: 8px;
    margin-bottom: 2px;
  }
}

ion-label.input-error {
  color: red !important;
}

ion-item {
  --background: white;
  --border-radius: 5px;
  &.input {
    --border-color: #E6E7E3;
    --border-width: 1px !important;

  }

  ion-input {
    font-size: 16px;
    --placeholder-color: #BFC1BC;
    --placeholder-opacity: 1;
    --padding-start: 10px
  }

  &.item-has-focus,
  &.item-has-value {
    --border-width: 0;
  }

  > ion-select {
    width: 100%;
    margin-right: 0px;
    background: transparent;
    height: 100%;
    text-align: left;
  }

  > ion-icon {
    color: var(--ion-color-primary);
  }

  > ::slotted([slot="end"]) {
    margin-inline-start: var(--ion-margin) !important;
  }

  > ion-label {
    white-space: initial !important;
  }

  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.disabled {
  ion-input {
    color: #B5B5B5 !important;
  }

  .lock-icon {
    bottom: 5px;
    position: absolute;
    right: 0;
    width: 10px;
    height: 13px;
  }
}

.input-error {
  --color: red;
  --placeholder-color: red;
  margin-bottom: 0 !important;
}

.error-text {
  margin: 4px 0;
  width: 100%;
  color: red;
  font-size: x-small;
}

.terms {
  color: var(--ion-color-black);
  font-size: 14px;
  line-height: 21px;
  .underline {
    text-decoration: underline;
    margin: 0;
  }
  ion-button {
    z-index: 2;
  }
}


body {
  font-family: AppFont, sans-serif;
  color: var(--ion-color-black);
}

app-impressum,
app-tos,
app-privacy {
  ion-header {
    background: white;
  }
}

h1 {
  margin: 0;
  font-size: xxx-large;
}

h2, h3, h4, h5 {
  font-family: AppFontExtraBold, sans-serif;
  margin: 0;
}

.no-text-transform {
  text-transform: none !important;
}

.text-small {
  font-size: small;
}

.text-medium {
  font-size: medium;
}

.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.text-xx-large {
  font-size: xx-large;
}

.place-items-center {
  place-items: center !important;
}

.white {
  fill: white;
  color: white;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.black {
  fill: black;
  color: black;
}

.flex-1 {
  flex: 1;
}

.relative {
  position: relative;
}

.grey {
  fill: #878787;
  color: #878787;
}

.dark {
  fill: var(--ion-color-black);
  color: var(--ion-color-black);
}

app-venue-suggestion {
  display: block;
}

app-email-action,
app-sign-in,
app-sign-in-order,
app-sign-up,
app-maintenance-page,
app-email-confirmation {
  ion-header {
    background: var(--ion-color-secondary);
    // @include desktop {  don`t know about descktop version?
    //   background: transparent;
    // }
  }
}

app-account {
  ion-header {
    background: var(--ion-color-primary);
  }
}


app-menu {
  background: white !important;
}

.main-color {
  fill: var(--ion-color-primary);
  color: var(--ion-color-primary);
}


.second-color {
  fill: var(--ion-color-secondary);
  color: var(--ion-color-secondary);
}

.gray {
  fill: var(--ion-color-gray-darkest);
  color: var(--ion-color-gray-darkest);
}

.invisible {
  opacity: 0;
}

.flex {
  display: flex;
}

ion-footer::before {
  display: none;
}

ion-button,
.add-to-order-button {
  cursor: pointer;
  --background: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary-shade);
  --background-hover: var(--ion-color-primary-shade);
  --riple-color: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
  --color: var(--ion-color-primary-contrast);
  min-height: 54px;
  font-family: AppFontExtraBold, sans-serif;
  text-align: center;
  --border-radius: 5px;
  --box-shadow: none;
  font-size: 18px;
  margin: 0;
  text-transform: capitalize;

  span {
    font-family: AppFontExtraBold, sans-serif !important;
    font-size: 18px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    color: #FFFFFF !important;
  }

  &.button-small {
    font-size: small;
    min-height: auto;

    ion-icon {
      margin-right: 4px;
    }
  }

  &.invalid {
    background: darkgrey;
    color: grey;
  }
  &.white {
    --background: white;
    color: var(--ion-color-primary);
    --background-activated: #E5EBEA;
    --background-hover: #E5EBEA;
    --background-focused: #E5EBEA;
    --riple-color: white;
  }
  &.content-only {
    width: auto;
    height: auto;
    border: 0;
    margin: 0;
    padding: 0 5px;
    --padding-start: 0;
    --padding-end: 0;
    --box-shadow: none;
    --background: none;

    ion-icon {
      margin-right: 0;
    }
  }
}

.bold {
  font-weight: bold;
}

.menu-row ion-icon .icon-inner svg path {
  fill: red !important;
}

.product-info {
  .action-sheet-group {
    height: 300px;
    padding: 32px 30px !important;

    .action-sheet-title {
      font-family: AppFont-bold, sans-serif;
      color: #333333;
      font-size: 26px;
      padding: 0;

      .action-sheet-sub-title {
        font-family: AppFont, sans-serif;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.overflow-auto {
  overflow: auto;
}

ion-fab-button {
  --background: transparent !important;
}

.hours-modal {
  @include desktop {
    ion-backdrop {
      background: #000000 !important;
    }
  }
}

.modal-wrapper {
  min-height: 85vh !important;

  > .modal-wrapper {
    max-width: 450px;
  }
}

.product-modal {
  .modal-wrapper {
    @include desktop {
      width: 450px !important;
      height: auto !important;
    }
  }
}

.hours-modal .modal-wrapper {
  height: calc(100% - 65px);
  bottom: 0;
  position: absolute;
  display: block;

  @include desktop {
    height: 600px;
    width: 480px;
    position: unset;
    bottom: unset;
  }
}

.short-info-modal {
  background: rgba(0, 0, 0, 0.2);
}

.short-info-modal > .modal-wrapper {
  border-radius: 5px;
  width: 95vw;
  @include desktop {
    width: 350px;
  }
}

.item-interactive.ion-valid {
  --highlight-background: var(--ion-color-primary);
}

.ion-color-white {
  color: #fff;
}

.google-search-input {
  input {
    font-size: 15px !important;
    padding: 20px 0 !important;
  }
}

.menu-popover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  .popover-content {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    @include desktop {
      width: 315px;
      height: auto;
      min-height: auto;
      top: 75px !important;
      left: unset !important;
      right: 12px !important;
    }
  }

  .popover-arrow {
    right: 20px !important;

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
    }
  }
}

.home-popover {

  .popover-content {
    width: 285px;
    border-radius: 0;
    left: 50px !important;
    top: 58px !important;
    @include desktop {
      top: 68px !important;
      left: 0 !important;
      right: 0 !important;
      margin: 0 auto;
    }
    @media screen and (max-width: 320px) {
      width: 250px;
    }
  }

  .popover-arrow {
    left: 70px !important;
    top: 50px !important;

    @include desktop {
      left: -220px !important;
      top: 60px !important;
      right: 0;
      margin: auto;
    }

    &:after {
      background: #fff !important;
      border-radius: 0 !important;
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.step-heading {
  font-family: AppFont-black, sans-serif;
  font-size: 28px;
  text-align: left;
  margin: 10px 0 20px;
}

.small-padding {
  .step-heading {
    font-size: 18px;
    margin: 0 0 25px;
  }
}

.position-relative {
  position: relative !important;
}

.height-auto {
  height: auto !important;
}

.pac-container {
  width: 100%;
  padding: 8px 20px !important;
  margin-top: 5px;
  border: none !important;
  background: #F5F5F5;
    border: 0.5px solid #D2CED0;
    border-radius: 5px;
  .pac-item {
    border-top: none !important;
    font-family: AppFontRegular, sans-serif;
    padding: 16px 0;
    line-height: 1 !important;
    font-size: 14px !important;
    border-bottom: 0.5px solid #E8E8E8;

    &:last-child {
      border-bottom: none;
    }

    .pac-icon {
      display: none !important;
    }

    .pac-matched {
      font-family: AppFontRegular, sans-serif;
      font-weight: bold;
    }
  }
}

// .preorder-type {
//   background: var(--ion-color-secondary);
//   font-size: x-small;
//   height: auto;
//   margin: 0 0 0 10px;
//   padding: 2px 4px;
//   color: #fff;
//   border-radius: 4px;
// }

ion-radio {
  margin: 10px;
  --border-color: var(--ion-color-checkbox);
  --border-color-checked: var(--ion-color-primary);
  --background-checked: var(--ion-color-primary);
  --color-checked: var(--ion-color-checkbox);
  --color: var(--ion-color-checkbox);
}

ion-checkbox {
  margin: 10px;
  --border-color: var(--ion-border-color);
  --border-color-checked: var(--ion-color-primary);
  --background-checked: var(--ion-color-primary);
  --color-checked: var(--ion-color-checkbox);
  --color: var(--ion-color-checkbox);
}

.pointer {
  cursor: pointer !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
  input {
    cursor: pointer !important;
  }
}

ion-row.center {
  justify-content: center;
  height: 100%;
  align-items: center;
}

.cdk-overlay-pane {
  bottom: 12px !important;
  position: relative !important;

  .mat-snack-bar-container {
    background: var(--ion-color-primary) !important;
    .mat-simple-snack-bar-content {
      font-family: AppFont, sans-serif;
      font-size: 15px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }
}

app-order, app-my-orders {
  background: #F9F9F9 !important;
  background-position: center top;

  ion-content {
    background: #fff !important;
    --background: #fff !important;
  }
}

app-payment-success {
  background: #fff !important;
  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
.display-contents {
  display: contents;
}

.display-block {
  display: block;
}

.display-grid {
  display: grid;
}

.label-floating {
  z-index: 4;
}

app-burger-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #000000;
  cursor: pointer;
}

.underline {
  color: var(--ion-color-secondary);
  text-decoration: underline;
}

ion-picker {
  touch-action: none;
}

.cc-animate {
  transition: transform 0.3s ease-in-out !important;
}

.cc-revoke {
  transform: translateY(100%) !important;
}

.cc-active.cc-revoke {
  transform: translateY(0) !important;
}


@include desktop {
  ion-picker-column {
    overflow: auto;
  }
  .picker-opts {
    top: unset !important;
    transform: none !important;
    pointer-events: none;
  }
  .picker-opt {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    transform: none !important;
  }
  .picker-opt-selected {
    transition: 100ms;
    font-size: 30px;
    font-weight: bold;
  }
}

.flex-grow {
  display: flex;
  flex-grow: 1;
}

.align-center {
  text-align: center;
}
ion-searchbar ion-icon {
  left: 3px !important;
  right: auto !important;
}
.sc-ion-searchbar-md-h{
  padding-inline-start: 26px !important
}
.searchbar-clear-button.sc-ion-searchbar-md {
  display: block !important;
}
.searchbar-clear-icon.sc-ion-searchbar-md {
  width: 14px !important;
}
.searchbar-search-icon.sc-ion-searchbar-md {
  width: 14px !important;
}
.searchbar-input.sc-ion-searchbar-md {
  box-shadow: unset !important;
  padding-inline-start: 35px !important;
  padding-top: 12px;
  padding-bottom: 12px;

  --placeholder-color: var(--ion-color-font);
  --placeholder-opacity: 1;
  --color: var(--ion-color-black);

  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}



ion-header:after {
  display: none;
}

ion-modal.auto-height {
  background: rgba(0, 0, 0, 0.2);
  --height: auto;

  .modal-wrapper {
    min-height: unset !important;

    .ion-page {
      position: relative;
      contain: content;
      max-height: 95vh;

      .modal-content {
        overflow: auto;
      }
    }
  }
}

ion-modal.align-bottom {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
  }
}

ion-modal.align-bottom-only-mobile {
  background: rgba(0, 0, 0, 0.2);

  .modal-wrapper {
    position: absolute;
    bottom: 0;
    @include desktop {
      bottom: unset !important;
    }
  }
}

ion-modal.allow-gps-modal .modal-wrapper {
  @include mobile {
    width: 95vw;
  }
}

.light-grey-transparent {
  background: rgba(0, 0, 0, 0.2);
  transition: 0.1s linear;
}

app-promo-code {
  display: block;
}


.sidebar {
  ion-slides {
    display: contents;

    > div {
      display: contents !important;
    }
  }

  ion-slide {
    display: contents;
  }
}


.wpwl-group-button {
  display: flex;
  place-content: center;
  flex-direction: row;
}

.wpwl-wrapper {
  width: 100%
}

.wpwl-control {
  background: white;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 4px 8px;
  margin-bottom: 15px;
}

.wpwl-label {
  font-size: 12px;
  padding: 0px;
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 999;
  background: white;
  padding: 1px;
}

.wpwl-wrapper-brand {
  width: 80%;
}

.wpwl-brand-card {
  width: 20%;
}

.wpwl-sup-wrapper-state {
  display: none;
}

.wpwl-sup-wrapper-street2 {
  display: none;
}

.cc_dialog {
  width: 100% !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  padding: 16px !important;
  background: var(--ion-color-secondary) !important;

  p {
    font-size: 10px !important;
  }

  h1 {
    font-size: 16px;
  }
}

.cc_cp_f_powered_by {
  display: none !important;
  content: none !important;
}

.cc_cp_m_content_entry {
  height: auto !important;
  max-height: 500px !important;
  overflow: auto !important;
}

.checkbox_cookie_consent {
  display: none;
}

.cc_cp_f_save {
  button {
    background: var(--ion-color-secondary) !important;
  }
}

.cc_b_cp {
  color: var(--ion-color-secondary) !important;
  background: white !important;
  border: 2px solid white !important;
  border-radius: 28px !important;
}

.cc_b_ok {
  color: white !important;
  border: 2px solid white !important;
  background-color: var(--ion-color-secondary) !important;
  border-radius: 28px !important;

}


app-add-to-home {
  position: absolute;
  bottom: 0;
}

ion-row.center-vertical {
  > * {
    align-self: center;
  }
}

.dashed-border {
  border: 2px dashed var(--ion-color-primary);
}


.tip-picker {
  .picker-columns {
    width: 30%;
    min-width: 200px;
    margin: 0 auto;

    ion-picker-column > .picker-opts > .picker-opt-selected {
      font-size: 25px;
      color: var(--ion-color-primary);
      font-weight: bold;
    }

    .sign-column {
      width: 30px;
    }

    .comma-column {
      width: 30px;
    }
  }
}

.padding-5 {
  padding: 5px;
}

.max-height-100 {
  max-height: 100%;
}

ion-col.center-horizontal > * {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.allow-gps-modal > .modal-wrapper {
  // background: transparent url(#{$brandAssets}/allow_gps_modal_background.png) center center no-repeat !important;
  background: white !important;
  background-size: cover !important;
  --width: 362px !important;
  border-radius: 10px;

  > .ion-page {
    background: none;
  }
}

.picker-opt-selected {
  font-weight: bold;
}

ion-modal {
  backdrop-filter: blur(1.5px);
}

.line-break-anywhere {
  line-break: anywhere;
}

.item-modal > .modal-wrapper {
  max-width: 450px;
}
ion-item {
  --padding-start:15px !important;
}
app-payment-modal{ 
  h1 {
    font-weight: bold !important;
    line-height: 20px !important;
    color: var(--ion-color-black) !important;
    margin: 25px 20px 30px !important;
    font-size: 20px !important;
  }
  .payment-input {
    --payment-modal-item-background:white !important;
    border-radius: 10px !important;
  }
  .wpwl-label {
    color: #54504C;
  }
  .wpwl-control{

  }
  iframe {
    background: white;
  }


  .wpwl-button-pay{
    float: none !important;
    width: 100%;
    background: var(--ion-color-primary);
    border-radius: 28px;
    background-color: var(--ion-color-primary);
    border-color: var(--ion-color-primary);
  }
}

ion-item {
  border: 1px solid var(--ion-border-color);
  box-sizing: border-box;
  border-radius: 5px;
  
  ion-label {
    font-family: AppFontRegular !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #000000 !important;
  }

  ion-icon {
    zoom: 1.0w !important;
  }
}

.container{
  position: relative;
  margin-bottom: 20px;
  ion-label {
    position: absolute;
    top: -10px;
    background: white;
    z-index: 999;
    left: 10px;
    padding: 0px 4px;

    font-family: AppFontRegular, sans-serif;
    font-size: 11px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.75);
  }
}
.sign-up-alert {
  .alert-title {
    font-family: AppFont, sans-serif;
    font-weight: 600;
    font-size: 20px;
  }
  .select-interface-option {
    padding: 8px;
    height: 60px !important;
    .alert-button-inner {
      border: 1px solid #E5E5E5;
      border-radius: 5px;
      padding: 5px;
      min-height: 33px;
      .alert-radio-label {
        padding: 37px;
        font-family: AppFontThin, sans-serif;
      }
    }
  }
  .alert-radio-group {
    border-bottom: none !important;

  }
  .alert-button-group {
    display: flex;
    justify-content: center;
    button {
      width: 46%;
      font-family: AppFont, sans-serif;
      font-weight: 700;
      font-size: 14px !important;
      .alert-button-inner {
        text-align: center !important;
        justify-content: center;
      }
    }
    button:first-child {
      color: var(--ion-color-secondary) !important;
      border: 2px solid var(--ion-color-secondary) !important;
      border-radius: 28px !important;
    }
    button:last-child {
      background:  var(--ion-color-secondary) !important;;
      color: white !important;
      border-radius: 28px !important;
    }
  }
  
}
.block-venues {
  background: white !important;
}
.map-toggle-container {
  height: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;

  ion-icon:first-child {
    margin-right: 5px !important;
  }
  .mode-icon{
    background: white !important;
    border: 1px solid black;
    border-radius: 50px;
    border: 1px solid var(--ion-border-color) !important;
    padding: 9px !important;
    zoom: 1 !important;
    fill: black !important;
    height: 18px !important;
    width: 18px !important;
  }
  .mode-icon.selected {
    background: var(--ion-color-primary) !important;
    color: black !important;
    transition: 0.3s linear;
    border: 1px solid #007042 !important;
    fill: white !important;
  }
}
.map-toggle {
  padding: 3px 10px !important;
  background:  #fff !important;
  .title {
    font-family: 'Playfair Display' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    text-transform: none !important;
    transform: matrix(1, 0, 0.01, 1, 0, 0);

    margin-top: 0 !important;
  }
}
.block-venues {
  margin: 20px 10px !important;
}

.cluster {
  display: flex;
  justify-content: center;

  > img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    content: url(/assets/icon/maps_marker_numbers.svg);
  }

  > div {
    display: contents;
    line-height: 2rem !important;

    > span {
      z-index: 2;
      color: var(--ion-color-main);
      font-family: AppFontBold, sans-serif !important;
      padding-top: 6px;
      font-size: 16px;
    }
  }
}

.ion-activated {
  --background: #fff;
  --color: #fff;
}
.container {
  position: relative;
  margin-bottom: 20px;

  ion-label {
    position: absolute;
    top: -7px;
    background: white;
    z-index: 999;
    left: 10px;
    padding: 0px 4px;
    font-size: 11px;
    color: #6B6B6B;
    font-family: AppFontRegular, sans-serif;
  }
}

.input-error {
  --color: red;
  --placeholder-color: red;
  margin-bottom: 0 !important;
}

.error-text {
  margin: 4px 0;
  width: 100%;
  color: red;
  font-size: x-small;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px var(--ion-color-medium);
  ;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-medium);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-primary);
}

.error_container {
  height: 15px;
  position: relative;
  margin-bottom: 5px;

  ion-note {
    font-size: 10px !important;
  }
}


.error_container_highter {
  height: 48px;
}

.input-error {
  position: absolute;
  opacity: 0;
  margin-left: 10px;
  color: rgb(119, 25, 25);
}

.input_info {
  position: absolute;
  opacity: 0;
  margin-left: 10px;
  color: #6B6B6B;
  left: 0;
  opacity: 0;
  color: #6B6B6B;
  
}

.ion-invalid.ion-info {
  --highlight-background: #6B6B6B;
} 
.visible {
  opacity: 1;
  transition: opacity 0.3s;
}
.select-gender {
  .alert-wrapper {
    min-width: 350px;
  }

  .alert-title {
    font-family: AppFont, sans-serif;
    margin: 10px 0px;
  }

  .alert-radio-group {
    border-top: 0.5px solid rgba(178, 192, 192, 0.5);
    border-bottom: none;
    padding: 10px;
    button {
        height: 55px;
        font-family: AppFontRegular, sans-serif;
        padding: 10px;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        margin-bottom: 10px;
      }
  }

  .alert-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 15px;

    button {
      height: 55px;
      font-family: AppFontBold, sans-serif;
      text-align: center;
      width: 47%;
      border-radius: 5px;
      span {
        text-transform: capitalize;
        font-size: 16px;
      }
      .alert-button-inner {
        justify-content: center;
      }
    }

    button:first-child {
      color: black;
    }

    button:last-child {
      background: var(--ion-color-primary);
      span {
        color: white !important;
      }
    }

  }
}
ion-item::part(native) {
  border-style: none;
}