@import "../theme/mixins";
@import "http://fonts.fontstorage.com/import/playfair.css";

$brand: 'starbucks';
$brandAssets: "/assets/#{$brand}";

@font-face {
  font-family: AppFont;
  src: url("../assets/fonts/NaupakaRegular.ttf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/fonts/PlayfairDisplayBold-nRv8g.ttf");
}
@font-face {
  font-family: AppFontExtraBold;
  src: url("../assets/fonts/PlayfairDisplayExtrabold-1GDw2.ttf");
}
@font-face {
  font-family: AppFontRegular;
  src: url("../assets/fonts/PlayfairDisplayRegular-ywLOY.ttf");
}
@font-face {
  font-family: AppFontMedium;
  src: url("../assets/fonts/PlayfairDisplayMedium-9YKZK.ttf");
}




:root {
  --brand: $brand;
	--ion-color-primary: #02615E;
	--ion-color-primary-rgb: 2,97,94;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #025553;
	--ion-color-primary-tint: #1b716e;

	--ion-color-secondary: #FDA3A1;
	--ion-color-secondary-rgb: 253,163,161;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #df8f8e;
	--ion-color-secondary-tint: #fdacaa;

  --ion-color-tertiary: #00754A;
  --ion-color-tertiary-rgb: 0,117,74;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #006741;
  --ion-color-tertiary-tint: #1a835c;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #66605a;
  --ion-color-dark-rgb: 102,96,90;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #5a544f;
  --ion-color-dark-tint: #75706b;

  --ion-color-medium: #6B6B6B;
  --ion-color-medium-rgb: 107,107,107;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #5e5e5e;
  --ion-color-medium-tint: #7a7a7a;

  --ion-color-light: #E2DCD5;
  --ion-color-light-rgb: 226,220,213;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #c7c2bb;
  --ion-color-light-tint: #e5e0d9;

  --ion-color-checkbox: #007042;
  --ion-color-checkbox-rgb: 0,112,66;
  --ion-color-checkbox-contrast: #ffffff;
  --ion-color-checkbox-contrast-rgb: 255,255,255;
  --ion-color-checkbox-shade: #00633a;
  --ion-color-checkbox-tint: #1a7e55;

  --ion-font-family: AppFont, sans-serif;
  --ion-margin: 12px;
  --ion-padding: 12px;

  --ion-color-font: #000000;
  --ion-background-color: #FFFFFF;
  --ion-border-color:#D2CED0;
}

h1, h2, h3, h4, h5 {
  font-family: AppFontBold;
  font-size: 16px;
  color: var(--ion-color-font);
}

p, span {
  font-family: 'Playfair Display';
  font-weight: 400;
  font-size: 12px;
  color: var(--ion-color-font);
}

.ion-page,
ion-footer,
ion-content {
  background: white;
  --background: white;
}
app-menu-modal {
  background: var(--ion-color-primary) !important;

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal {
  background: #F9F9F9 !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-home, app-venue-link {
  background: var(--ion-color-primary) !important;

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}

